<template>

    <Head title="Log in" />

    <jet-authentication-card>
        <template #logo>
            <a :href="route('home')">
                <img
                    class="w-auto h-10"
                    src="/images/logo.svg"
                    alt="Pushey"
                />
            </a>
        </template>

        <jet-validation-errors class="mb-4" />

        <div
            v-if="status"
            class="mb-4 text-sm font-medium text-green-600"
        >
            {{ status }}
        </div>

        <form @submit.prevent="submit">
            <div>
                <jet-label
                    for="email"
                    value="Email"
                />
                <jet-input
                    id="email"
                    type="email"
                    class="block w-full mt-1"
                    v-model="form.email"
                    required
                    autofocus
                />
            </div>

            <div class="mt-4">
                <jet-label
                    for="password"
                    value="Password"
                />
                <jet-input
                    id="password"
                    type="password"
                    class="block w-full mt-1"
                    v-model="form.password"
                    required
                    autocomplete="current-password"
                />
            </div>

            <div class="block mt-4">
                <label class="flex items-center">
                    <jet-checkbox
                        name="remember"
                        v-model:checked="form.remember"
                    />
                    <span class="ml-2 text-sm text-gray-600">Remember me</span>
                </label>
            </div>

            <div class="flex items-center justify-end mt-4">
                <Link
                    v-if="canResetPassword"
                    :href="route('password.request')"
                    class="text-sm text-gray-600 underline hover:text-gray-900"
                >
                Forgot your password?
                </Link>

                <jet-button
                    class="ml-4"
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                >
                    Log in
                </jet-button>
            </div>
        </form>
    </jet-authentication-card>
</template>

<script>
import JetAuthenticationCard from '@Dashboard/Jetstream/AuthenticationCard.vue'
import JetAuthenticationCardLogo from '@Dashboard/Jetstream/AuthenticationCardLogo.vue'
import JetButton from '@Dashboard/Jetstream/Button.vue'
import JetInput from '@Dashboard/Jetstream/Input.vue'
import JetCheckbox from '@Dashboard/Jetstream/Checkbox.vue'
import JetLabel from '@Dashboard/Jetstream/Label.vue'
import JetValidationErrors from '@Dashboard/Jetstream/ValidationErrors.vue'
import { Head, Link } from '@inertiajs/vue3';

import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        Head,
        JetAuthenticationCard,
        JetAuthenticationCardLogo,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        JetValidationErrors,
        Link,
    },

    props: {
        canResetPassword: Boolean,
        status: String
    },

    data() {
        return {
            form: this.$inertia.form({
                email: '',
                password: '',
                remember: false
            })
        }
    },

    methods: {
        submit() {
            this.form
                .transform(data => ({
                    ...data,
                    remember: this.form.remember ? 'on' : ''
                }))
                .post(this.route('login'), {
                    onFinish: () => this.form.reset('password'),
                })
        }
    }
})
</script>
